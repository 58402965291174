import {
  LOADING,
  FAQS_LIST,
  USERS_LIST,
  USERS_CONTACT_LIST,
  CATEGORY_LIST,
  QUIZ_LIST,
  QUESTION_LIST,
  DESHBOARD,
  GET_ASSIGMENT_DETAILS,
  USER_DETAILS,
} from "../common/constant";

const initialState = {
  loading: false,

  categories_delete: {},
  users_list: {},
  users_contact_list: {},
  category_list: {},
  quiz_list: {},
  question_list: {},
  deshboard: {},
  get_assigment_details: {},
  get_user_details: {},

  faqs_list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };

    case FAQS_LIST:
      return { ...state, faqs_list: action.payload };
    case USERS_LIST:
      return { ...state, users_list: action.payload };
    case USERS_CONTACT_LIST:
      return { ...state, users_contact_list: action.payload };
    case CATEGORY_LIST:
      return { ...state, category_list: action.payload };
    case QUIZ_LIST:
      return { ...state, quiz_list: action.payload };
    case QUESTION_LIST:
      return { ...state, question_list: action.payload };
    case DESHBOARD:
      return { ...state, deshboard: action.payload };
    case GET_ASSIGMENT_DETAILS:
      return { ...state, get_assigment_details: action.payload };
    case USER_DETAILS:
      return { ...state, get_user_details: action.payload };
    // case OTP_BOX_FORGOT:
    //   return { ...state, serach_pro_pub: action.payload };

    default:
      return state;
  }
};
