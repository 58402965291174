import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";

import { AsyncPaginate } from "react-select-async-paginate";
import { URL } from "../../Redux/common/url";

function Quiz() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const quiz_list = useSelector((state) =>
    state?.allapi?.quiz_list ? state?.allapi?.quiz_list : {}
  );

  console.log(quiz_list);

  const listdata = quiz_list?.data ? quiz_list?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  const [seleceteManger, setseleceteManger] = useState({});
  const [seleceteCateUpdate, setseleceteCateUpdate] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };

  const showModal = (e) => {
    console.log(e);
    setcateDetail(e);

    if (e?.category !== null) {
      const seriresDetatail = e?.category;
      seriresDetatail.value = e?.category_id;
      seriresDetatail.label = e?.category?.name;

      setseleceteCateUpdate(seriresDetatail);
    }
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (!seleceteManger?.id) {
      dispatch(
        allapiAction.update_quiz({
          //   category_id: seleceteManger?.id,
          cat_id: seleceteCateUpdate?.id,
          name: cateDetail?.name,
          page: page,
          category_id: 0,
          type: "all",
          quiz_id: cateDetail?.id,
          quiz_time: cateDetail?.quiz_time,
        })
      );
    } else {
      dispatch(
        allapiAction.update_quiz({
          category_id: seleceteManger?.id,
          cat_id: seleceteCateUpdate?.id,
          name: cateDetail?.name,
          page: page,
          //   category_id: 0,
          type: "All",
          quiz_id: cateDetail?.id,
          quiz_time: cateDetail?.quiz_time,
        })
      );
    }

    // dispatch(adminAction.getRoleList('dhsg'))
    setIsModalOpen(false);
    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({});

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);

    setAddModalOpen(true);
  };

  //   console.log(cateDetailAdd);

  const [seleceteCateCreate, setseleceteCateCreate] = useState({});

  const handleOkAdd = () => {
    if (!cateDetailAdd?.name) {
      toast.error("please enter name");
    } else if (!seleceteCateCreate?.id) {
      toast.error("please select category");
    } else if (!cateDetailAdd?.quiz_time) {
      toast.error("please enter quiz time");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("name", cateDetailAdd?.name);
      data.append("category_id", seleceteCateCreate?.id);
      data.append("quiz_time", cateDetailAdd?.quiz_time);

      dispatch(allapiAction.create_quiz(data));

      setcateDetailAdd({ name: "", quiz_time: "" });
      // dispatch(adminAction.getRoleList('dhsg'))
      setAddModalOpen(false);
      setseleceteCateCreate(null);
      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleTostatusUpdate = async (id, status) => {
    if (!seleceteManger?.id) {
      dispatch(
        allapiAction.update_quiz({
          page: page,

          type: "all",
          quiz_id: id,
          active: status,
        })
      );
    } else {
      dispatch(
        allapiAction.update_quiz({
          category_id: seleceteManger?.id,
          page: page,

          type: "All",
          quiz_id: id,
          active: status,
        })
      );
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Quiz time",
      dataIndex: "quiz_time",
      key: "quiz_time",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (name, item) => <>{item?.category?.name}</>,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.active == 0 ? "Inactive" : "Active"}>
              <button
                className="filter-button ml-3"
                onClick={() =>
                  hendleTostatusUpdate(item?.id, item?.active == 0 ? 1 : 0)
                }
              >
                {item?.active == 0 ? "Inactive" : "Active"}
              </button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>

            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    setShowDelete(false);

    if (!seleceteManger?.id) {
      dispatch(
        allapiAction.delete_quiz({
          quiz_id: e,
          page: page,
          category_id: 0,
          type: "all",
        })
      );
    } else {
      dispatch(
        allapiAction.delete_quiz({
          quiz_id: e,
          page: page,
          category_id: seleceteManger?.id,
          type: "All",
        })
      );
    }

    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  useEffect(() => {
    dispatch(allapiAction.quiz_list({ page: 1, category_id: 0, type: "all" }));

    return () => {};
  }, []);

  const hendleTofilter = () => {
    dispatch(
      allapiAction.quiz_list({
        page: 1,
        category_id: seleceteManger?.id,
        type: "All",
      })
    );

    return () => {};
  };
  const handleFilterReset = () => {
    dispatch(
      allapiAction.quiz_list({
        page: 1,
        category_id: 0,
        type: "all",
      })
    );
    setseleceteManger(null);
    return () => {};
  };

  const loadOptionsgetManager = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    console.log(search);
    console.log(page);

    const response = await fetch(
      URL.API_BASE_URL +
        `/api/all_category?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const options = responseJSON?.data?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };
  const loadOptionsgetQuizCreate = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    const response = await fetch(
      URL.API_BASE_URL +
        `/api/all_category?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const options = responseJSON?.data?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };
  const loadOptionsgetQuizUpdate = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    const response = await fetch(
      URL.API_BASE_URL +
        `/api/all_category?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const options = responseJSON?.data?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.quiz_listSearch({
            page: 1,
            category_id: seleceteManger?.id,
            type: "All",
            search: e,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.quiz_listSearch({
            page: 1,
            category_id: 0,
            type: "all",
            search: e,
          })
        );
        // setseleceteManger(null);
        return () => {};
      }

      // dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
      // return () => {};
    } else {
      // quiz_listSearch
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.quiz_list({
            page: 1,
            category_id: seleceteManger?.id,
            type: "All",
            // search: search,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.quiz_list({
            page: 1,
            category_id: 0,
            type: "all",
          })
        );
        // setseleceteManger(null);
        return () => {};
      }

      // dispatch(allapiAction.allcategory(e));

      // return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.quiz_listSearch({
            page: e,
            category_id: seleceteManger?.id,
            type: "All",
            search: search,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.quiz_listSearch({
            page: e,
            category_id: 0,
            type: "all",
            search: search,
          })
        );

        return () => {};
      }
    } else {
      if (seleceteManger?.id) {
        dispatch(
          allapiAction.quiz_list({
            page: e,
            category_id: seleceteManger?.id,
            type: "All",
            // search: search,
          })
        );

        return () => {};
      } else {
        dispatch(
          allapiAction.quiz_list({
            page: e,
            category_id: 0,
            type: "all",
          })
        );
        // setseleceteManger(null);
        return () => {};
      }
    }
  };

  return (
    <div>
      {/* update */}
      <Modal
        title="Update Quiz"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24} className=" mt-15">
          <label>Select category</label>
          <AsyncPaginate
            // key={managerKey}
            placeholder="select category"
            value={seleceteCateUpdate}
            loadOptions={loadOptionsgetQuizUpdate}
            onChange={setseleceteCateUpdate}
            additional={{
              page: 1,
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>Name</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetail?.name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>Quiz time</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="quiz time"
            name="quiz_time"
            type={"number"}
            value={cateDetail?.quiz_time}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
      </Modal>
      {/* create */}
      <Modal
        title="Create Quiz"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24} className=" mt-15">
          <label>Select category</label>
          <AsyncPaginate
            // key={managerKey}
            placeholder="select category"
            value={seleceteCateCreate}
            loadOptions={loadOptionsgetQuizCreate}
            onChange={setseleceteCateCreate}
            additional={{
              page: 1,
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>Name</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetailAdd?.name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <label>Quiz time</label>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="quiz time"
            name="quiz_time"
            type={"number"}
            value={cateDetailAdd?.quiz_time}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Quiz"}
            subHeading={`Total ${quiz_list?.total || 0} Quiz`}
          />
        </div>

        {/* <div> */}

        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
                        type="text"
                        autoComplete="new-off"
                        className='form-control'
                        // onChange={(e) => handleChange("TestCode", e.target.value)}
                        placeholder='Test Name/Test Code'
                        // value={values?.TestCode}
                    /> */}
          <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          />
        </Col>
        {/* </div> */}

        <div className="pl-5 ml-5">
          <button className="filter-button" onClick={() => hendleTofilter()}>
            Filter
          </button>
          {/* <Tooltip title="Add">
            <span className="jop_btn" onClick={() => hendleTofilter()}>
              Filter
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip> */}
        </div>
        <div className="pl-5 ml-5">
          <button
            className="clear-button clear-button1"
            onClick={() => handleFilterReset()}
          >
            Reset
          </button>
          {/* <Tooltip title="Add">
            <span className="jop_btn" onClick={() => hendleTofilter()}>
              Filter
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip> */}
        </div>

        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>
        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              setPage(current);
            },
            hideOnSinglePage: true,

            total: quiz_list?.total ? quiz_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Quiz;
