import React, { useEffect } from "react";
import { Col, Row } from "antd";
import Boxes from "./Boxes";
import { useDispatch } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import axios from "axios";

function Home() {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(allapiAction.dashboard({ }));

    return () => {};
  }, []);


  return (
    <div>
      <Row gutter={[20, 20]} type="flex">
        <Col lg={18} md={24} sm={24} xs={24}>
          <div className="dashboardBox p-10">
            {/* <CenteralHealthLine /> */}
            <Boxes />
          </div>
        </Col>
        {/* <Col lg={6} md={24} sm={24} xs={24}>
                    <div>
                        <Boxes />
                    </div>
                </Col> */}
      </Row>
    </div>
  );
}

export default Home;
