import React, { useEffect, useState } from "react";
import {
  DashboardOutlined,
  UsergroupAddOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  SettingOutlined,
  UserOutlined,
  FileOutlined,
  ApartmentOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import SidebarFooter from "./SidebarFooter";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { useBasePath } from "../../hooks/useBasePath";
import SvgIcon from "../../const/SvgIcon";
import { ShowMenu } from "../../utils";
const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const { openKey, selectKey } = useBasePath();

  return (
    <Sider
      className="layout_sidebar"
      collapsible
      collapsed={collapsed}
      width={240}
      trigger={!collapsed ? <SidebarFooter /> : null}
    >
      <Logo collapsed={collapsed} />
      <div className="sidebar_menus">
        <Menu
          mode="inline"
          selectedKeys={selectKey}
          defaultOpenKeys={openKey}
          style={{ background: "#fecc4e" }}
          items={[
            {
              key: "dashboard",
              icon: <DashboardOutlined />,
              label: "Dashboard Management",
              onClick: () => {
                navigate("/");
              },
            },
            {
              key: "users",
              icon: <QuestionCircleOutlined />,
              label: "Users",
              style: { display: ShowMenu(["Users"]) },
              onClick: () => {
                navigate("/users");
              },
            },
            {
              key: "chat",
              icon: <QuestionCircleOutlined />,
              label: "Chat",
              style: { display: ShowMenu(["Chat"]) },
              onClick: () => {
                navigate("/chat");
              },
            },
            {
              key: "category",
              icon: <QuestionCircleOutlined />,
              label: "Category",
              style: {
                display: ShowMenu(["Category", "Sub Category", "Subject"]),
              },
              children: [
                {
                  key: "category",
                  label: "Category",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Category"]) },
                  onClick: () => {
                    navigate("/category");
                  },
                },

                {
                  key: "sub-category",
                  label: "Sub Category",
                  icon: <QuestionOutlined />,
                  style: { display: ShowMenu(["Sub Category"]) },
                  onClick: () => {
                    navigate("/sub-category");
                  },
                },
                {
                  key: "subject",
                  label: "Subject",
                  icon: <QuestionOutlined />,
                  style: { display: ShowMenu(["Subject"]) },
                  onClick: () => {
                    navigate("/subject");
                  },
                },
              ],
            },
            // {
            //   key: "category",
            //   icon: <FileSearchOutlined />,
            //   label: "Category",
            //   style: { display: ShowMenu(["Category"]) },
            //   onClick: () => {
            //     navigate("/category");
            //   },
            // },
            // {
            //   key: "quiz",
            //   icon: <ApartmentOutlined />,
            //   label: "Quiz",
            //   style: { display: ShowMenu(["Quiz"]) },
            //   onClick: () => {
            //     navigate("/quiz");
            //   },
            // },
            // {
            //   key: "question",
            //   icon: <QuestionCircleOutlined />,
            //   label: "Question",
            //   style: { display: ShowMenu(["Question"]) },
            //   onClick: () => {
            //     navigate("/question");
            //   },
            // },

            // {
            //     key: 'investor-management',
            //     icon: <QuestionCircleOutlined />,
            //     label: 'Investor Management',
            //     style: { display: ShowMenu(["investor Category", "Financials Pages", 'investor Pages']) },
            //     children: [
            //         {
            //             key: 'investor Category',
            //             label: 'Categories',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["investor Categorys"]) },
            //             onClick: () => {
            //                 navigate("/investor-category")
            //             },

            //         },

            //         {
            //             key: 'financials',
            //             label: 'Financials',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["Financials Pages"]) },
            //             onClick: () => {
            //                 navigate("/investor-financials")
            //             },
            //         },
            //         {
            //             key: 'investor',
            //             label: 'Investors Information',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["investor Pages"]) },
            //             onClick: () => {
            //                 navigate("/investor-information")
            //             },
            //         },
            //         {
            //             key: 'corporate-governance',
            //             label: 'Corporate Governance',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["investor Pages"]) },
            //             onClick: () => {
            //                 navigate("/investor-corporate-governance")
            //             },
            //         },
            //     ]
            // },
            // {
            //     key: 'seo-management',
            //     icon: <SvgIcon.seoManagementIcon />,
            //     label: 'SEO Management',
            //     style: { display: ShowMenu(["seo", 'Seo Pages']) },
            //     children: [
            //         {
            //             key: 'seo',
            //             label: 'SEO',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["Seo Pages"]) },
            //             onClick: () => {
            //                 navigate("/seo-management")
            //             },
            //         },
            //     ]
            // },
            // {
            //     key: 'ticket-management',
            //     icon: <FileOutlined />,
            //     label: 'Ticket Management',
            //     style: { display: ShowMenu(["Ticket Management"]) },
            //     onClick: () => {
            //         navigate("/ticket-management")
            //     }
            // },
            // {
            //     key: 'hr-management',
            //     icon: <SvgIcon.HrIcon />,
            //     label: 'HR Management',
            //     style: { display: ShowMenu(["Job Applications", "Job Posts"]) },
            //     children: [
            //         {
            //             key: 'job-applications',
            //             label: 'Job Applications',
            //             icon: <FileOutlined />,
            //             style: { display: ShowMenu(["Job Applications"]) },
            //             onClick: () => {
            //                 navigate("job-applications")
            //             },
            //         },
            //         {
            //             key: 'job-post',
            //             label: 'Job Posts',
            //             icon: <FileOutlined />,
            //             style: { display: ShowMenu(["Job Posts"]) },
            //             onClick: () => {
            //                 navigate("jobs")
            //             },
            //         },

            //     ]
            // },
            // {
            //     key: 'user-management',
            //     icon: < UsergroupAddOutlined />,
            //     label: 'Users Management',
            //     style: { display: ShowMenu(["Users", "Roles"]) },
            //     children: [
            //         {
            //             key: 'users',
            //             label: 'Users',
            //             icon: <UserOutlined />,
            //             style: { display: ShowMenu(["Users"]) },
            //             onClick: () => {
            //                 navigate("/users")
            //             },
            //         },
            //         {
            //             key: 'roles',
            //             label: 'Roles',
            //             icon: <UserOutlined />,
            //             style: { display: ShowMenu(["Roles"]) },
            //             onClick: () => {
            //                 navigate("/roles")
            //             },
            //         }
            //     ]
            // },
          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
