import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteToken } from '../../utils';

const TopBar = ({ collapsed, setCollapsed }) => {
    const navigate = useNavigate();
    const handleLogout = () => {
        DeleteToken();
        navigate("/login", { replace: true });
    }
    return (
        <div className='flex justify-content-between align-items-center'>
            <div>{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
            })}</div>
            <div className='mr-20'>
                <span className='logout-btn' onClick={() => handleLogout()}>Logout<LogoutOutlined /></span>
            </div>
        </div >
    )
}

export default TopBar