export const URL = {
  // dev

  // API_BASE_URL: "http://192.168.0.65:8000",
  // API_BASE_URL: "https://appdevelopmentcompanyjaipur.com/aurra",
  API_BASE_URL: "https://csapi.devhubtech.com",
  // API_BASE_URL: "http://192.168.0.2:8000",
 
  // API_BASE_URL: "http://gamesdeveloper.in:3002",

  // apipublic

  login_admin: "/api/admin/login",
  create_quiz_category: "/api/admin/create_subject_category",
  all_category_main: "/api/admin/get_subject_category_page",
  update_category: "/api/admin/update_subject_category",
  delete_category: "/api/admin/delete_subject_category",
  create_quiz: "/api/admin/create_subject_sub_category",
  get_subject_subCategory_page: "/api/admin/get_subject_subCategory_page",
  update_subject_subcategory: "/api/admin/update_subject_subcategory",
  delete_subject_subcategory: "/api/admin/delete_subject_subcategory",
  get_subjects_page: "/api/admin/get_subjects_page",
  add_subject: "/api/admin/add_subject",
  delete_subject: "/api/admin/delete_subject",
  update_subject: "/api/admin/update_subject",
  users_list: "/api/admin/users_list",
  delete_user: "/api/admin/delete_user",
  assignment_details: "/api/admin/assignment_details",
  user_detail: "/api/admin/user_detail",
  updateAssignmentStatus: "/api/admin/updateAssignmentStatus",
  submitSolution: "/api/admin/submitSolution",

  users: "/api/users",
  user_contact: "/api/user_contact",
  quiz_list: "/api/admin/get_subject_subCategory_page",
  all_category: "/api/all_category",
  delete_quiz: "/api/delete_quiz",
  update_quiz: "/api/update_quiz",
  questions_list: "/api/questions_list",
  delete_question: "/api/delete_question",
  update_question: "/api/update_question",
  create_quiz_question: "/api/create_quiz_question",
  dashboard: "/api/dashboard",
  user_allcontact: "/api/user_allcontact",
  user_inactive: "/api/user_inactive",
  quiz_active: "/api/quiz_active",

  createcategories: "/apiVendor/createcategories",
  getCategory: "/publicApi/getCategory",
  getsubsubcate: "/publicApi/getsubsubcate",
  getsubsubcateHome: "/publicApi/getsubsubcateHome",
  signupSeller: "/publicApi/signupSeller",
  otpVerify: "/publicApi/otpVerify",
  productDetails: "/publicApi/productDetails",
  getsellerProductListPublice: "/publicApi/getsellerProductListPublice",
  gettagsProductListPublice: "/publicApi/gettagsProductListPublice",
  getsellerProductListPublicefilCate:
    "/publicApi/getsellerProductListPublicefilCate",
  getsellerProductListPublicebycategory:
    "/publicApi/getsellerProductListPublicebycategory",
  getsellerprofile: "/publicApi/getsellerprofile",
  whatsappinquiresubmit: "/publicApi/whatsappinquiresubmit",
  getsubsubCategoryList: "/publicApi/getsubsubCategoryList",
  createSubscription: "/publicApi/createSubscription",
  getPages: "/publicApi/getPagesPu",
  emailenquirysubmit: "/publicApi/emailenquirysubmit",
  pageDetails: "/publicApi/pageDetails",
  searchProductpub: "/publicApi/searchProductpub",
  sendOtpSeller: "/publicApi/sendOtpSeller",
  otpVerifyseller: "/publicApi/otpVerifyseller",
  signinSellereSecurite: "/publicApi/signinSellereSecurite",
  footerPagepageListetPu: "/publicApi/footerPagepageListetPu",
  productclick: "/publicApi/productclick",
  getHomePro: "/publicApi/getHomePro",
  getSellerListPublic: "/publicApi/getSellerListPublic",
  getsellerProductListPublicebymaincategory2:
    "/publicApi/getsellerProductListPublicebymaincategory2",

  // apiAdmin
  signinSeller: "/publicApi/signinSeller",
  getsellerProductList: "/sellerApi/getsellerProductList",
  createSellerProduct: "/sellerApi/createSellerProduct",
  searchSellerProduct: "/sellerApi/searchSellerProduct",
  deleteSellerProduct: "/sellerApi/deleteSellerProduct",
  createBussnissPro: "/sellerApi/createBussnissPro",
  getBussProfile: "/sellerApi/getBussProfile",
  updateWhatsaapNumber: "/sellerApi/updateWhatsaapNumber",
  verifyWhatsapp: "/sellerApi/verifyWhatsapp",
  updateBussLogo: "/sellerApi/updateBussLogo",
  updateBussProfile: "/sellerApi/updateBussProfile",
  getPackageList: "/sellerApi/getPackageList",
  createPurchase: "/sellerApi/createPurchase",
  getsellerTransaction: "/sellerApi/getsellerTransaction",
  getprofile: "/sellerApi/getprofile",
  updatesellerProduct: "/sellerApi/updatesellerProduct",
  getSellerTicketList: "/sellerApi/getSellerTicketList",
  createSellerTickets: "/sellerApi/createSellerTickets",
  getmessageSupportList: "/sellerApi/getmessageSupportList",
  createMessage: "/sellerApi/createMessage",
  deleteacountByseller: "/sellerApi/deleteacountByseller",
  copyProduct: "/sellerApi/copyProduct",
  getInvoice: "/sellerApi/getInvoice",
  getEmailenquirySeller: "/sellerApi/getEmailenquirySeller",
  getsellerWhatsappinquire: "/sellerApi/getsellerWhatsappinquire",
  searchtags: "/sellerApi/searchtags",
  passwordChange: "/sellerApi/passwordChange",
  topViewnewSeller: "/sellerApi/topViewnewSeller",
  sellerDashboardState: "/sellerApi/sellerDashboardState",
  getExpireDate: "/sellerApi/getExpireDate",
  getsliderList: "/publicApi/getsliderList2",
  getBlogListPub: "/publicApi/getBlogListPub",
  getBlogDetails: "/publicApi/getBlogDetails",
  gethomePop: "/publicApi/gethomePop",
  getlistfaqs: "/publicApi/getlistfaqs",
  getInvoceDetails: "/publicApi/getInvoceDetails",
};
