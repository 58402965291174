export const LOADING = "LOADING";
//user constant

export const CREATE_CATEGORIES = "CREATE_CATEGORIES";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_SUB_SUB_CATEGORIES = "GET_SUB_SUB_CATEGORIES";
export const GET_SUB_SUB_CATE_LIST = "GET_SUB_SUB_CATE_LIST";
export const GET_SUB_SUB_CATE_LIST_FIL = "GET_SUB_SUB_CATE_LIST_FIL";
export const GET_PACKAGE_LIST = "GET_PACKAGE_LIST";
export const SHOW_MENU = "SHOW_MENU";
export const GET_SELLER_PRODUCT_LIST = "GET_SELLER_PRODUCT_LIST";
export const GET_SELLER_PRODUCT_LIST_PUBLICE =
  "GET_SELLER_PRODUCT_LIST_PUBLICE";
export const GET_TAGS_PRODUCT_LIST_PUBLICE = "GET_TAGS_PRODUCT_LIST_PUBLICE";
export const GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY =
  "GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY";
export const GET_SELLER_TICKET_LIST = "GET_SELLER_TICKET_LIST";
export const GET_SELLER_TANSACTION = "GET_SELLER_TANSACTION";
export const OTP_BOX = "OTP_BOX";
export const OTP_BOX_FORGOT = "OTP_BOX_FORGOT";
export const GET_BUSS_PRO = "GET_BUSS_PRO";
export const GET_PROFILE = "GET_PROFILE";
export const GET_MESSAGE_LISTS = "GET_MESSAGE_LISTS";
export const GET_INVOICE = "GET_INVOICE";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_SELLER_PROFILE = "GET_SELLER_PROFILE";
export const GET_PAGES = "GET_PAGES";
export const GET_WHATSAPP_QU = "GET_WHATSAPP_QU";
export const GET_EMAIL_QU = "GET_EMAIL_QU";
export const PAGE_DETAILS = "PAGE_DETAILS";
export const SEARCH_TAGS = "SEARCH_TAGS";
export const SEARCH_PRODUCT_PUB = "SEARCH_PRODUCT_PUB";
export const GET_FOOTER_DATA_ALL = "GET_FOOTER_DATA_ALL";
export const GET_HOME_ALL_PRO = "GET_HOME_ALL_PRO";
export const TOP_VIEW_ALL_TYPE = "TOP_VIEW_ALL_TYPE";
export const SELLER_LIST = "SELLER_LIST";
export const DESHBOARD_STATE = "DESHBOARD_STATE";
export const SLIDER_LIST = "SLIDER_LIST";
export const BLOG_LIST = "BLOGLIST";
export const BLOG_DETAILS = "BLOG_DETAILS";
export const GET_EXPIRE_DATE = "GET_EXPIRE_DATE";
export const HOME_POPS = "HOME_POPS";
export const FAQS_LIST = "FAQS_LIST";

export const USERS_LIST = "USERS_LIST";
export const USERS_CONTACT_LIST = "USERS_CONTACT_LIST";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const QUIZ_LIST = "QUIZ_LIST";
export const QUESTION_LIST = "QUESTION_LIST";
export const DESHBOARD = "DESHBOARD";
export const GET_ASSIGMENT_DETAILS = "GET_ASSIGMENT_DETAILS";
export const USER_DETAILS = "USER_DETAILS";