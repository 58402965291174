import firebase from 'firebase'

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyA1GlIP99WVfq4K6-MpVmQAoneMg5HLy6U",
  authDomain: "coursesaviour-1f44b.firebaseapp.com",
  projectId: "coursesaviour-1f44b",
  storageBucket: "coursesaviour-1f44b.appspot.com",
  messagingSenderId: "686156477269",
  appId: "1:686156477269:web:18ce350a6718b55ff975dc",
  measurementId: "G-Y37C2WC1ZK"
})

const db = firebaseApp.firestore()

const db2 = firebaseApp.database()

export { db, db2 }

