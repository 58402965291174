import React, { useEffect, useState } from "react";
// import 'antd/dist/antd.css';
import {
  Layout,
  Row,
  Col,
  Checkbox,
  Modal,
  Input,
  Button,
  List,
  Avatar,
} from "antd";
import { CheckOutlined, SendOutlined } from "@ant-design/icons";

import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

// import { ExclamationCircleFilled } from "@ant-design/icons";
// import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";

import { AsyncPaginate } from "react-select-async-paginate";
import { URL } from "../../Redux/common/url";
import { db2 } from "../../firebaseApp";
import firebase from "firebase";
import { GetTokenDetails } from "../utils";
import axios from "axios";
// import QRCode from "qrcode.react";
const { Sider, Content } = Layout;
function Chat() {
  const dbRef2 = db2.ref(`tickets`);
  const containerRef = React.useRef(null);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openuser, setOpenuser] = useState(false);
  const [openSolution, setOpenSolution] = useState(false);
  const [openQuote, setOpenQuote] = useState(false);

  const get_assigment_details = useSelector((state) =>
    state?.allapi?.get_assigment_details
      ? state?.allapi?.get_assigment_details
      : {}
  );
  const get_user_details = useSelector((state) =>
    state?.allapi?.get_user_details ? state?.allapi?.get_user_details : {}
  );

  useEffect(() => {
    const databaseRef = db2.ref(`tickets`);

    // Use the 'orderByChild' method to filter by the 'text' field
    databaseRef.on("value", (snapshot) => {
      const filteredData = [];
      snapshot.forEach((childSnapshot) => {
        filteredData.push(childSnapshot.val());
      });

      filteredData?.sort((a, b) => b?.updatedAt - a?.updatedAt);
      setFilteredTickets(filteredData);
    });

    // Clean up the Firebase listener when the component unmounts
    return () => {
      databaseRef.off();
    };
  }, []);

  const [ticketby, setticketby] = useState("");
  const [userId, setuserId] = useState("");

  const adminDetail = GetTokenDetails();

  const [messgaedata, setData] = useState([]);
  const [unredcount, setunredcount] = useState({});

  useEffect(() => {
    containerRef.current.scrollTop = containerRef.current.scrollHeight;

    if (messgaedata?.length > 0) {
      dbRef2.child(`${ticketby}`).update({
        // ticket: tiketIdT,
        // userid: "622898bfa00531831c6787cf",
        adminunseencount: 0,
        // updatedAt: firebase.database.ServerValue.TIMESTAMP,
      });
    }
  }, [messgaedata]);

  useEffect(() => {
    // Attach an event listener to fetch data when the component mounts

    if (ticketby) {
      const dbRef = db2.ref(`tickets/${ticketby}/chats`);
      const dbRef3 = db2.ref(`tickets/${ticketby}`);
      dbRef.on("value", (snapshot) => {
        if (snapshot.exists()) {
          const messages = Object.values(snapshot.val());

          // Sort messages by 'createdAt' in descending order
          messages.sort((a, b) => a.createdAt - b.createdAt);

          setData(messages);
        }
      });
      dbRef3.on("value", (snapshot) => {
        if (snapshot.exists()) {
          const messages = snapshot.val();
          setunredcount(messages);
          // Sort messages by 'createdAt' in descending order
          // messages.sort((a, b) => a.createdAt - b.createdAt);

          // setData(messages);
        }
      });

      dbRef2.child(`${ticketby}`).update({
        // ticket: tiketIdT,
        // userid: "622898bfa00531831c6787cf",
        adminunseencount: 0,
        // updatedAt: firebase.database.ServerValue.TIMESTAMP,
      });
      // Clean up the event listener when the component unmounts
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      return () => {
        dbRef.off();
      };
    }
  }, [ticketby]);

  const [msg, setMsg] = useState("");

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      // // router.push("/searching-product?product=" + sechData);
      // history.push("/question?search=" + SearchValue);
      // // dispatch(
      // //   productsAction.getproductSreachPublice({
      // //     pageNumber: 1,
      // //     key: sechData,
      // //   })
      // // );
      // setAnchor1(false);
      sendMessage2();
    }
  };

  const sendMessage2 = () => {
    // Push a new message to the Firebase database
    const dbRef = db2.ref(`tickets/${ticketby}/chats`);
    dbRef.push({
      msg: msg,
      read: false,
      senderid: adminDetail?.id,
      active: true,
      type: "text",
      createdAt: firebase.database.ServerValue.TIMESTAMP,
    });
    setMsg("");
    // Clear the input field after sending the message
    // setMessage("");
    newUpdate();
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  };

  const newUpdate = () => {
    // dbRef2.child(`${ticketby}/userlastmessage`).update({

    //   // username: userToken?.name,
    //   assignmentid: "2",
    //   timestamp: firebase.database.ServerValue.TIMESTAMP,
    // });
    dbRef2.child(`${ticketby}`).update({
      // ticket: tiketIdT,
      // userid: "622898bfa00531831c6787cf",
      lastMsg: msg,
      adminunseencount: 0,
      userunseencount:
        unredcount?.userunseencount == undefined
          ? 1
          : unredcount?.userunseencount + 1,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    });
  };

  // assignment_details
  // assignment_details

  const [assigmentdId, setassigmentdId] = useState("");

  const assignment_details = (e) => {
    setassigmentdId(e);

    dispatch(allapiAction.assignment_details({ id: e }));
  };

  const user_detail = (e) => {
    setOpenuser(true);
    dispatch(allapiAction.user_detail({ id: userId }));
  };

  const [amount, setamount] = useState("");

  const hendletoqoute = () => {
    // updateAssignmentStatus

    // 'quote_amount' => 'string',

    if (!amount) {
      toast.error("enter amount");
    } else {
      dispatch(
        allapiAction.updateAssignmentStatus({
          id: assigmentdId,
          quote_amount: amount,
        })
      );
      setamount("");
      setOpenQuote(false);
    }
  };
  const [discription, setdiscription] = useState("");
  const [discriptionRaiting, setdiscriptionRaiting] = useState("");
  const [postAssignmentasgeastimg, setpostAssignmentasgeastimg] = useState([]);

  console.log(discription);
  console.log(postAssignmentasgeastimg);

  const fileSelectedCarImg = (event) => {
    let ImagesArray = Object.entries(event.target.files).map((e) => e[1]);
    setpostAssignmentasgeastimg([...postAssignmentasgeastimg, ...ImagesArray]);
  };

  const removeCourse = (event) => {
    let array = [...postAssignmentasgeastimg];
    let coun = array.indexOf(event);
    if (coun > -1) {
      array.splice(coun, 1);
      setpostAssignmentasgeastimg(array);
    }
  };

  const hendleTosubmitSolution = () => {
    if (!discription) {
      toast.error("enter description");
    } else {
      const data = new FormData();
      data.append("assignment_id", assigmentdId);
      data.append("description", discription);
      data.append("rating", discriptionRaiting);
      if (postAssignmentasgeastimg?.length > 0) {
        postAssignmentasgeastimg.map((file, i) => {
          data.append("attachments[" + i + "]", file);
        });
      }

      dispatch(allapiAction.submitSolution(data));
      setOpenSolution(false);
    }
  };

  // submitSolution

  const [show1, setShow1] = useState(false);
  const [DocUrl, setDocUrl] = useState("");

  console.log(DocUrl);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const hendledoc = (e) => {
    setShow1(true);
    setDocUrl(e);
  };

  const handleDownload = () => {
    // Create a temporary anchor element
    fetch(URL.API_BASE_URL + DocUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a URL for the blob data
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = "downloaded_file.jpg"; // You can specify the file name here
        a.style.display = "none";
        document.body.appendChild(a);

        // Simulate a click event to trigger the download
        a.click();

        // Clean up and remove the anchor element
        document.body.removeChild(a);

        // Revoke the URL to free up memory
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
    const a = document.createElement("a");
    a.href = URL.API_BASE_URL + DocUrl;
    a.target = "_blank";
    a.download = "downloaded_file.jpg"; // You can specify the file name here
    a.style.display = "none";
    document.body.appendChild(a);

    // Simulate a click event to trigger the download
    a.click();

    // Clean up and remove the anchor element
    document.body.removeChild(a);
  };

  const scrollToLastQuote = () => {
    const chatContainer = containerRef.current;
    const quoteMessages = chatContainer.querySelectorAll('[data-type="quote"]');
    console.log(quoteMessages);
    if (quoteMessages.length > 0) {
      const lastQuoteMessage = quoteMessages[quoteMessages.length - 1];

      console.log(lastQuoteMessage.offsetTop);
      chatContainer.scrollTo({
        top: lastQuoteMessage.offsetTop - 200,
        behavior: "smooth",
      });
    }
  };
  const scrollToLastSolution = () => {
    const chatContainer = containerRef.current;
    const quoteMessages = chatContainer.querySelectorAll(
      '[data-type="solution"]'
    );
    console.log(quoteMessages);
    if (quoteMessages.length > 0) {
      const lastQuoteMessage = quoteMessages[quoteMessages.length - 1];

      console.log(lastQuoteMessage.offsetTop);
      chatContainer.scrollTo({
        top: lastQuoteMessage.offsetTop - 200,
        behavior: "smooth",
      });
    }
  };
  return (
    <div>
      {/*  */}
      <Modal
        title="Docs"
        centered
        open={show1}
        onOk={() => setShow1(false)}
        onCancel={() => setShow1(false)}
        width={1000}
      >
        <div className="m-5">
          {/* <a
            onClick={() => {
              handleDownload();
            }}
          >
            {" "}
            download
          </a> */}
          <button onClick={handleDownload}>Download File</button>
          <iframe
            src={URL.API_BASE_URL + DocUrl}
            title="Image Viewer"
            width="100%"
            height="500"
            frameBorder="0"
          ></iframe>
        </div>
      </Modal>

      <Modal
        title="Assigment"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <p>{get_assigment_details?.question?.question}</p>
        <p>{get_assigment_details?.question?.status}</p>
        <p>{get_assigment_details?.assignment_status}</p>
        <p>{get_assigment_details?.deadline}</p>
        {/* <p>some contents...</p>
        <p>some contents...</p> */}
        <Button
          type="primary"
          // shape="circle"

          // icon={<SendOutlined />}
          size="large"
          onClick={() => {
            setOpenQuote(true);
          }}
        >
          {" "}
          Quote
        </Button>
        {/* <button
          className="btn btn-primary"
          onClick={() => {
            setOpenQuote(true);
          }}
        >
          quote
        </button> */}
      </Modal>

      {/* user Detaisl */}
      <Modal
        title="User Details"
        centered
        open={openuser}
        onOk={() => setOpenuser(false)}
        onCancel={() => setOpenuser(false)}
        width={1000}
      >
        <p>{get_assigment_details?.question?.question}</p>
        {/* <p>some contents...</p>
        <p>some contents...</p> */}
      </Modal>

      {/* submit Quote */}
      <Modal
        title="Submit Qoute"
        centered
        open={openQuote}
        onOk={() => hendletoqoute(false)}
        onCancel={() => setOpenQuote(false)}
        width={500}
      >
        <div className="">
          <div>
            <label>Amount</label>
            <input
              className="form-control"
              value={amount}
              onChange={(e) => {
                setamount(e?.target?.value);
              }}
            />
          </div>
        </div>
      </Modal>
      {/* submit solution */}
      <Modal
        title="Submit Solution"
        centered
        open={openSolution}
        onOk={() => hendleTosubmitSolution(false)}
        onCancel={() => setOpenSolution(false)}
        width={1000}
      >
        <div className="">
          <div>
            <label>discription</label>
            <textarea
              placeholder="enter discription"
              className="form-control"
              value={discription}
              onChange={(e) => {
                setdiscription(e?.target?.value);
              }}
            ></textarea>
          </div>
          <div>
            <label>rating</label>
            <input
              placeholder="enter rating"
              className="form-control"
              value={discriptionRaiting}
              onChange={(e) => {
                setdiscriptionRaiting(e?.target?.value);
              }}
            />
          </div>
          <div className="col-lg-6 mb-3 upload_file">
            <span>
              <i className="fas fa-file-upload"></i> upload file
              <input
                type="file"
                name="image[]"
                accept="image/*,.txt,.zip,.xls,.xlsx,.docx,.pdf,.pptx"
                multiple
                onChange={fileSelectedCarImg}
              />
            </span>

            {postAssignmentasgeastimg &&
              postAssignmentasgeastimg?.map((data, i) => {
                return (
                  <div
                    className="upload_file1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={data?.name}
                    key={i}
                  >
                    {" "}
                    <div
                      className="closeBtns"
                      onClick={(e) => {
                        removeCourse(data);
                      }}
                    >
                      <i class="fas fa-times"></i>
                    </div>{" "}
                    {data?.type == "image/png" ? (
                      <img src="/images/img.png" alt="" />
                    ) : data?.type == "application/pdf" ? (
                      <img src="/images/1.png" alt="" />
                    ) : data?.type == "image/jpeg" ? (
                      <img src="/images/img.png" alt="" />
                    ) : data?.type == "text/plain" ? (
                      <img src="/images/4.webp" alt="" />
                    ) : data?.type ==
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                      <img src="/images/2.png" alt="" />
                    ) : data?.type ==
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                      <img src="/images/5.webp" alt="" />
                    ) : data?.type ==
                      "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                      <img src="/images/7.png" alt="" />
                    ) : (
                      <img src="/images/img.png" alt="" />
                    )}{" "}
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Chat"}
            // subHeading={`Total ${question_list?.total || 0} Question`}
          />
        </div>
      </div>

      {/* <a href="https://www.amazon.in/Shining-Diva-Fashion-Jewellery-14737r/dp/B0BWMN2Z32?crid=2TWTRPUTML7U3&keywords=jewellery&qid=1694520507&sprefix=jewellery%2B%2Caps%2C219&sr=8-11&th=1&linkCode=li3&tag=giftsforyou0c-21&linkId=856c9305b5b3af590adcd86268988009&language=en_IN&ref_=as_li_ss_il" target="_blank">
      <img border="0" src="//ws-in.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0BWMN2Z32&Format=_SL250_&ID=AsinImage&MarketPlace=IN&ServiceVersion=20070822&WS=1&tag=giftsforyou0c-21&language=en_IN" />

      </a>
      <img src="https://ir-in.amazon-adsystem.com/e/ir?t=giftsforyou0c-21&language=en_IN&l=li3&o=31&a=B0BWMN2Z32" width="1" height="1" border="0" alt="" style={{border:"none !important", margin:"0px !important" }}/>
      
      <iframe
        sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
        style={{ width: "520px", height: "240px" }}
        marginwidth="0"
        marginheight="0"
        scrolling="no"
        frameborder="0"
        src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=giftsforyou0c-21&language=en_IN&marketplace=amazon&region=IN&placement=B0BWMN2Z32&asins=B0BWMN2Z32&linkId=ba8c5a4a7accb68268d105e1c725d47b&show_border=true&link_opens_in_new_window=true"
      ></iframe> */}
      {/* <QRCode value={"http://192.168.0.11:3001/chat"} /> */}
      <Layout style={{ minHeight: "100vh", background: "#ffffff" }}>
        <Sider
          width={300}
          className={"sidebarChat"}
          style={{ background: "#ffffff", padding: "20px" }}
        >
          <h4>User List</h4>
          <List
            dataSource={filteredTickets}
            renderItem={(item) => (
              <List.Item>
                {/* <List.Item.Meta
                //   avatar={<Avatar src="https://via.placeholder.com/40" />}
                  title={item?.ticket}
                  userName={item?.userlastmessage?.username}
                /> */}

                {/* sidbarChatActive */}
                <div
                  className={
                    item?.ticketid == ticketby
                      ? " sidbarChatActive"
                      : "sidbarChat"
                  }
                  onClick={() => {
                    setticketby(item?.ticketid);
                    assignment_details(item?.assignmentid);
                    setuserId(item?.userid);
                  }}
                >
                  <h4 className="ant-list-item-meta-title">{item?.ticketid}</h4>
                  <div className="unReadM">
                    {" "}
                    {item?.adminunseencount !== undefined ? (
                      item?.adminunseencount == 0 ? (
                        " "
                      ) : (
                        <p>
                          {item?.adminunseencount == 0
                            ? " "
                            : item?.adminunseencount}
                        </p>
                      )
                    ) : (
                      ""
                    )}{" "}
                  </div>
                  <p>{item?.username}</p>
                  <p>
                    {" "}
                    {item?.lastMsg?.length > 20
                      ? `${item?.lastMsg.substring(0, 20)}...`
                      : item?.lastMsg}
                  </p>
                </div>
              </List.Item>
            )}
          />
        </Sider>
        <Content style={{ padding: "20px", minHeight: "380px" }}>
          <Row justify="end">
            <Col span={24}>
              {/* <Row justify="end">
              <Checkbox className="whatsapp-checkbox">
                WhatsApp-Style Checkbox
              </Checkbox>
            </Row> */}
              <div className="chat-container">
                {assigmentdId && (
                  <div className="chat-header">
                    <div className="assigmentd">
                      <Row className="row">
                        <Col span={8} className="col-6">
                          {" "}
                          <h4>
                            {" "}
                            {get_assigment_details?.question?.question?.length >
                            60
                              ? `${get_assigment_details?.question?.question.substring(
                                  0,
                                  60
                                )}...`
                              : get_assigment_details?.question?.question}
                          </h4>
                        </Col>
                        <Col
                          span={8}
                          className="col-6"
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            type="text"
                            onClick={() => {
                              setOpen(true);
                            }}
                          >
                            View All
                          </Button>
                        </Col>
                        <Col
                          span={8}
                          className="col-6"
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            type="text"
                            onClick={() => {
                              setOpenSolution(true);
                            }}
                          >
                            Submit Solution
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                {assigmentdId && (
                  <div className="chat-header mt-10">
                    <div className="assigmentd">
                      <Row className="row">
                        <Col span={12} className="col-6">
                          {" "}
                          <h4>Chat with {unredcount?.username}</h4>
                        </Col>
                        <Col
                          span={12}
                          className="col-6"
                          style={{ textAlign: "right" }}
                        >
                          <Button
                            type="text"
                            onClick={() => {
                              user_detail(true);
                            }}
                          >
                            View User Details
                          </Button>
                          <Button
                            type="text"
                            onClick={() => {
                              scrollToLastQuote(true);
                            }}
                          >
                            Quotation
                          </Button>
                          <Button
                            type="text"
                            onClick={() => {
                              scrollToLastSolution(true);
                            }}
                          >
                            Solution
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                {/* <div className="chat-header">
                  <h4>Chat with {unredcount?.username}</h4>
                </div> */}
                <div
                  className="chat-messages"
                  style={{ maxHeight: "380px" }}
                  ref={containerRef}
                >
                  {/* userId */}

                  {messgaedata &&
                    messgaedata?.map((data, i) => {
                      const imageExtensions = /\.(jpg|jpeg|png|gif)$/i; // Image extensions
                      const pdfExtensions = /\.pdf$/i; // PDF extension
                      const excelExtensions = /\.(xlsx|xls)$/i; // Excel extensions

                      return (
                        <div
                          className={
                            data?.senderid == userId
                              ? "message outgoing"
                              : "message incoming"
                          }
                          data-type={data?.type}
                        >
                          {/* <Avatar src="https://via.placeholder.com/40" /> */}

                          <div className="messageChild">
                            <p>{data?.senderid == userId ? "User" : "You"}</p>

                            <p>{data?.msg}</p>
                            {data?.type == "solution" &&
                              data?.attachment &&
                              data?.attachment?.map((filedata, index) => {
                                return (
                                  <div
                                    onClick={(e) => {
                                      hendledoc(filedata?.attachment);
                                    }}
                                    className="solutiondata"
                                  >
                                    {imageExtensions.test(
                                      filedata?.attachment
                                    ) ? (
                                      <img src="/images/img.png" alt="" />
                                    ) : pdfExtensions.test(
                                        filedata?.attachment
                                      ) ? (
                                      <img src="/images/1.png" alt="" />
                                    ) : excelExtensions.test(
                                        filedata?.attachment
                                      ) ? (
                                      <img src="/images/2.png" alt="" />
                                    ) : (
                                      <img src="/images/5.webp" alt="" />
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}

                  {/* <div className="message outgoing">
                    <p>You: Hello!</p>
                  </div> */}
                </div>
                {assigmentdId && (
                  <div className="chat-input ">
                    <Input
                      placeholder="Type your message..."
                      size="large"
                      value={msg}
                      onKeyDown={onEnterPress}
                      onChange={(e) => {
                        setMsg(e.target.value);
                      }}
                      suffix={
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<SendOutlined />}
                          size="large"
                          onClick={(e) => {
                            sendMessage2(e);
                          }}
                        />
                      }
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>

      <div className="tableResponsive mt-10"></div>
    </div>
  );
}

export default Chat;
